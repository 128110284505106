import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { Container, Flex } from "./styled-components/Containers";
import CloseBtn from '../assets/svg-icons/close.svg';
import OpenBtn from '../assets/svg-icons/open.svg';

function Nav() {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    const handleScroll = (e) => {
      setIsOpen(false)
    }

    const onWindowClick = (e) => {
      e.stopPropagation();
      if (e.target.className.baseVal !== 'open-btn') {
        return setIsOpen(false)
      } else {
        setIsOpen(true)
      }
    }
    window.addEventListener('scroll', handleScroll, { passive: true });
    window.addEventListener('click', onWindowClick);
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <NavDiv>
      <Container navWidth>
        <Flex mobileFlexRow>
          <div className="logo">
            <Link to="/">
              <span className="lower">mgr </span>Dominika Hennig
            </Link>
          </div>
          <OpenBtn className='open-btn' onClick={(e) => { e.stopPropagation(); setIsOpen(true)} }></OpenBtn>
          <div className={isOpen ? 'menu open' : 'menu'}>
            <CloseBtn className='btn-close' style={{fill: "white"}} onClick={() => setIsOpen(false)}/>
            <Link to="/#trainings">treningi</Link>
            <Link to="/#about">o mnie</Link>
            {/* <Link to="/galeria">galeria</Link> */}
            <Link to="/#contact">contact</Link>
          </div>
        </Flex>
      </Container>
    </NavDiv>
  );
}

export const NavDiv = styled.nav`
  padding: 1em 1em;
  font-family: "Ovo", serif;
  text-transform: Uppercase;
  grid-column: 2/12;
  grid-row: 2/3;
  z-index: 0;
  display: flex;
  justify-content: space-between;
  z-index: 2;
  .logo {
    text-transform: capitalize;

    .lower {
      text-transform: lowercase;
    }
  }
  .btn-close {
    display: none;
  }
  .open-btn {
    display: none;
    cursor: pointer;
    @media screen and (max-width: 767px) {
      display: block;
    }
  }
  .menu {
    display: flex;
    width: 50%;
    justify-content: space-around;

    svg path {
      fill: var(--secondaryRed);
    }
    
    // @media (max-width: 674px) and (orientation: portrait) {
    //   display: none;
    // }
    @media screen and (min-width: 767px){
      .open-btn {
        display: none
      }
    }
    @media (max-width: 767px)  {
      display: flex;
      flex-direction: column;
      gap: 2em;
      position: fixed;
      background: var(--darkGrey);
      min-height: 110vh;
      padding: 8em 3em 30em;
      right: -100%;
      top: -1%;
      width: 65%;
      color: white;
      align-items: flex-end;
      transition: right .5s ease-in;

      .btn-close {
        display: block
      }

      a {
        color: white;
        font-size: 1.25em;
        font-weight: bold;
      }
    }
  }
  .open {
    right: 0;
    transition: right .5s ease-in;
  }
`;
export default Nav;
