import React from "react";
import Layout from "../components/Layout";
import Nav from "../components/Nav";
import { Container } from "../components/styled-components/Containers";
import styled from "styled-components";

function Gallery({ data }) {
  return (
    <Layout>
      <Nav />
        <Container>
            <GalleryWrapper></GalleryWrapper>
        </Container>  
    </Layout>
    
  );
}
export const Head = () => <title>Galeria Trenera Kungfu</title>
export default Gallery;
const GalleryWrapper = styled.div`
  max-width: 1000px;
  width: 100%;
  margin: auto;
  background: white;
  height: 300px;
`