import styled from "styled-components";

export const Container = styled.div`
    width: ${({navWidth}) => navWidth ? '100%' : '90%'};
    margin: 0 auto;
`
export const Flex = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
`